<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Advertiser Accounts Overview" />

    <DataTable
      table-key="advertiserAccountsOverview"
      row-key="id"
      title="Advertiser Accounts"
      :columns="columns"
      :rows="advertiserAccounts"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                $router.push({
                  path: '/manage/advertiser/accounts/edit/' + row.id
                }),
              label: 'Edit'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import DataTable from "@/components/DataTable";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
import axios from "axios";
moment.locale("en");

export default {
  name: "AdvertiserAccountsOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      filter: "",
      advertiser_accounts: [],
      columns: [
        {
          name: "id",
          label: "Account ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "name",
          label: "Account Name",
          field: "name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "business_name",
          label: "Business Name",
          field: "business_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "conversion_goal_event_type_name",
          label: "Conversion Goal Event",
          field: "conversion_goal_event_type_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "network",
          label: "Network",
          field: "network",
          format: val => this.capitalizeText(val),
          sortable: true,
          type: "dimension"
        },
        {
          name: "payout_method",
          label: "Payout Method",
          field: row => this.capitalizeText(row.bid_method),
          sortable: true,
          type: "dimension"
        },
        {
          name: "static_payout_value",
          label: "Static Payout Value",
          field: "static_payout_value",
          format: val => (!val ? "-" : val),
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric"
        },
        {
          name: "bid_value_scrub_percentage",
          label: "Payout Value Scrub %",
          field: "bid_value_scrub_percentage",
          format: val => val.length ? val + "%" : "-",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric"
        },
        {
          name: "lead_payout_value",
          label: "Lead Payout Value",
          field: "lead_payout_value",
          format: val => val.length ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val) : "-",
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {
    advertiserAccounts() {
      return this.loading ? [] : this.advertiser_accounts;
    }
  },
  mounted() {
    axios({
      url: "https://api-v2.jumpfeed.com/internal/advertiser-accounts"
    })
      .then(resp => {
        this.advertiser_accounts = resp.data.data;
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
      });
  }
};
</script>

<style scoped lang="scss"></style>
